import React from 'react';
import styled from "@emotion/styled";
import Helmet from "react-helmet";
import { Link, PageProps } from "gatsby";
import { respondFrom, breakpoints, css } from "~/styles";
import { ILocaleContext } from "~/localeContext";
import { apiSiteMetaQuery } from "~/graphql";
import { getAppWebRoot } from "~/utils/api";
import * as utils from "~/utils";

const PageWrapper = styled.div`
  max-width: 1680px;
  margin: auto;
  padding: 0 10px;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 0 20px;
    `
  )};
`;

const PageLogo = styled.div`
  margin: auto;
  margin-top: 40px;
  max-width: 450px;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 600px;
    `
  )};
  
  ${respondFrom(
    breakpoints.xl,
    css`
      width: 620px;
      position: absolute;
      top: 70px;
      left: 0;
      right: 25%;
      margin: auto;
      max-width: none;
    `
  )};

  img {
    max-width: 100%;
  }
`;

const Section = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 20px;

  ${respondFrom(
    breakpoints.xl,
    css`
      margin: 0;
      padding-top: 330px;
      flex-flow: row;
    `
  )};
  
`;

const TextsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 400px;
  margin: auto;

  > img {
    max-width: 100%;

    &.desktop {
      display: none;
    }
  }

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 600px;
    `
  )};
  
  ${respondFrom(
    breakpoints.xl,
    css`
      max-width: 45vw;
      margin: 0;

      > img {
        &.desktop {
          display: block
        }
        &.mobile {
          display: none;
        }
      }
    `
  )};

  ${respondFrom(
    1460,
    css`
      max-width: 40vw;
    `
  )};

  ${respondFrom(
    1600,
    css`
      max-width: 650px;
    `
  )};
`;

const MascotImage = styled.div`
  width: 300px;
  margin: auto;
  text-align: right;
  transform: translateX(55px);
  z-index: 5;

  > img {
    max-width: 200px;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      width: 650px;
      transform: translateY(0px);

      > img {
        max-width: 350px;
      }
    `
  )};

${respondFrom(
    breakpoints.xl,
    css`
      position: absolute;
      top: 150px;
      right: 0;
      left: 53vw;

      > img {
        max-width: 400px;
      }

      &::before {
        content: '';
        position: absolute;
        top: -200px;
        height: 200px;
        left: 228px;
        right: 0;
        margin: auto;
        width: 6px;
        background: black;
      }
    `
  )};

  ${respondFrom(
    1460,
    css`
      right: 0;
      left: auto;

      > img {
        max-width: 501px;
      }

      &::before {
        left: 120px;;
        width: 7px;
      }
    `
  )};
`;

const FooterWrapper = styled.div`
  height: 200px;
  width: calc(100% + 20px);
  margin-left: -10px;
  background-image: url('/images/prizes/prizes-shape-1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -10px;
  position: relative;
  padding-top: 45px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: -25px;
      height: 400px;
      width: calc(100% + 40px);
      margin-left: -20px;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      margin-top: 40px;
      padding: 0;
    `
  )};
`;

const Xbox = styled.div`
  position: absolute;
  top: -130px;
  left: -10px;
  right: 0;
  margin: auto;
  height: 200px;
  width: 300px;

  > img {
    max-height: 100%;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      height: 450px;
      width: 800px;
      top: -270px;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      bottom: 100px;
      left: 350px;
      right: 0;
      margin: auto;
      height: 450px;
      width: 400px;
    `
  )};

  ${respondFrom(
    1460,
    css`
      left: 250px;
      right: 0;
      height: 530px;
      width: 500px;
    `
  )};
`;

const FooterInner = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 450px;
  margin: auto;
  padding: 0 10px;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 800px;
      padding-top: 90px;
      padding-left: 30px;
      padding-right: 40px;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      max-width: none;
      padding-top: 200px;
      padding-left: 30px;
      padding-right: 40px;
    `
  )};
`;

const FooterLogo = styled(Link)`
  width: 80px;

  > img {
    max-width: 100%;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      width: 150px;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      width: 200px;
      margin-right: 80px;
    `
  )};
`;

const FooterContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;

  ${respondFrom(
    breakpoints.xl,
    css`
      flex-flow: row;
      padding-bottom: 50px;
    `
  )};
`;

const FooterText = styled.div`
  color: white;
  text-align: right;
  font-size: 12px;
  font-family: Heebo, sans-serif;
  font-weight: 700;

  ${respondFrom(
    breakpoints.md,
    css`
      font-size: 20px;
    `
  )};
`;

const FooterButton = styled.a`
  text-decoration: none;
  margin: 20px;
  width: 105px;

  > img {
    max-width: 100%;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin-bottom: 50px;
      width: 185px;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      margin: 0;
    `
  )};
`;

type MetaProps = JSX.IntrinsicElements["meta"]
interface MainLayout extends PageProps {
  pageContext: ILocaleContext;
}

const Prizes: React.FC<MainLayout> = ({ children, pageContext }) => {
  const siteMetadata = apiSiteMetaQuery();
  const metaDescription = (
    pageContext.translation.messages
      && pageContext.translation.messages['Create your gentle message and send it to the world!'])
        || siteMetadata.description
  const metaTitle = (
    pageContext.translation.messages
      && pageContext.translation.messages['Tic Tac® - Gentle messages'])
        || siteMetadata.title!;

  const constantMeta: MetaProps[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `${getAppWebRoot()}/images/cover.jpg`
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author!,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <>
      <PageWrapper>
        <Helmet meta={constantMeta.concat(constantMeta)}>
          <html lang="he" />
          <title>{metaTitle}</title>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap" rel="stylesheet" />
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-N2GCP4L');
            `}
          </script>
        </Helmet>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-N2GCP4L"
            height="0"
            width="0"
          ></iframe>
        </noscript>
        <PageLogo>
          <img src="/images/prizes/logo-prizes.png" alt="Logo" />
        </PageLogo>
        <Section>
          <TextsWrapper>
            <img src="/images/prizes/text-desktop-1.png" alt="Text desktop" className="desktop" />
            <img src="/images/prizes/text-mobile-1.png" alt="Text mobile" className="mobile" />
          </TextsWrapper>
          <MascotImage>
            <img src="/images/prizes/prizes-mascot.png" alt="Angel and clown mascot" />
          </MascotImage>
        </Section>
        <FooterWrapper>
          <Xbox>
            <img src="/images/prizes/xbox.png" alt="Xbox" />
          </Xbox>
          <FooterInner>
            <FooterLogo to="/">
              <img src="/images/prizes/prizes-tictac-logo.png" alt="Tic Tac logo" />
            </FooterLogo>
            <FooterContent>
              <FooterButton href="/">
                <img src="/images/prizes/prizes-btn-text.png" alt="Footer button" />
              </FooterButton>
              <FooterText>
                <div>תקופת הפעילות 25.2.21-4.2.21*</div>
                <div>בכפוף לתקנון הפעילות*</div>
              </FooterText>
            </FooterContent>
          </FooterInner>
        </FooterWrapper>
      </PageWrapper>
    </>
  );
};

export default Prizes;